<template>
  <div v-if="productDetail" class="inter-wrapper">
    <HeaderInter />
    <!-- 確認訂單 -->
    <div class="inter-content">
      <div class="order-area-group">
        <div class="order-area">
          <div class="sub-title">
            <span>訂單資訊</span>
          </div>
          <div class="box-group">
            <div class="box-items">
              <div class="box-th">
                商品
              </div>
              <div class="box-td">
                {{ productDetail.name }}
              </div>
            </div>
            <div class="box-items">
              <div class="box-th">
                方案
              </div>
              <div class="box-td">
                {{ orderItem.plan().plan_name }}
                {{ orderItem.priceName() | pricename }}
              </div>
            </div>
            <div class="box-items">
              <div class="box-th">
                價格
              </div>
              <div class="box-td">
                {{ orderItem.pricePointLabel() }}
              </div>
            </div>
            <div class="box-items">
              <div class="box-th">
                數量
              </div>
              <div class="box-td">
                {{ orderItem.qty() }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="orderItem.plan().description" class="order-area">
          <div class="sub-title">
            <span>方案說明</span>
          </div>
          <div
            class="box-article nl2br"
            v-text="orderItem.plan().description"
          />
        </div>

        <div v-if="buy_notice" class="order-area">
          <div class="sub-title">
            <span>購買注意事項</span>
          </div>
          <div class="box-article nl2br" v-text="buy_notice" />
        </div>

        <div class="order-area">
          <div class="sub-title">
            <span>使用方法</span>
          </div>
          <div class="box-article nl2br" v-text="orderItem.plan().guide" />
        </div>

        <div v-if="orderItem.plan().notice" class="order-area">
          <div class="sub-title">
            <span>注意事項</span>
          </div>
          <div class="box-article nl2br" v-text="orderItem.plan().notice" />
        </div>

        <div v-if="orderItem.plan().inclusion" class="order-area">
          <div class="sub-title">
            <span>費用包含</span>
          </div>
          <div class="box-article nl2br" v-text="orderItem.plan().inclusion" />
        </div>

        <div v-if="orderItem.plan().exclusion" class="order-area">
          <div class="sub-title">
            <span>費用不包含</span>
          </div>
          <div class="box-article nl2br" v-text="orderItem.plan().exclusion" />
        </div>

        <div v-if="getplantrusttext" class="order-area">
          <div class="sub-title">
            <span>信託/價金文字</span>
          </div>
          <div class="box-article nl2br" v-text="getplantrusttext" />
        </div>

        <div v-if="orderItem.plan().policy_cancel || orderItem.plan().refund_type === 2" class="order-area">
          <div class="sub-title">
            <span>取消政策</span>
          </div>
          <div
            class="box-article nl2br"
          >
            {{ orderItem.plan().policy_cancel || '不可取消' }}
          </div>
        </div>

        <div v-if="orderItem.plan().branches.length" class="order-area">
          <div class="sub-title">
            <span>適用分店</span>
          </div>
          <div
            v-for="(branch, index) in orderItem.plan().branches"
            :key="index"
            class="box-article"
          >
            {{ branch.area }} {{ branch.branch_name }}
            {{ branch.branch_address }} {{ branch.branch_phone }}
          </div>
        </div>
      </div>
    </div>
    <div class="price-items-fix">
      <div v-if="orderItem.type() === 'point'" class="info-box">
        <span>訂單金額</span>
        <em><big>{{ orderItem.pointTotalPoint() }}</big>點＋NT$<big>{{ orderItem.pointTotalPrice() | priceFormat }}</big></em>
      </div>
      <div v-else class="info-box">
        <span>訂單金額</span>
        <em>NT$<big>{{ orderItem.creditTotalPrice() | priceFormat }}</big></em>
      </div>
      <button class="btn btn-next" type="button" @click="fillInfo()">
        下一步
      </button>
    </div>
  </div>
</template>

<script>
import { getItem } from '@/api/shop'
import { getMember } from '@/api/user'
import HeaderInter from '@/components/HeaderInter'
import { showNotice, happyGoTimeNotice, getStoreCode } from '@/utils/tool'
import orderItem from '@/utils/orderItem'

export default {
  name: 'OrderInit',
  components: {
    HeaderInter
  },
  data() {
    return {
      productDetail: null,
      orderItem: orderItem,
      buy_notice: this.$store.getters.shopInfo.buy_notice
    }
  },
  computed: {
    getplantrusttext() {
      return this.orderItem.plan().trusttext
        ? this.orderItem.plan().trusttext
        : this.productDetail.trusttext
    }
  },
  created() {
    if (
      this.$store.getters.orderItem.item_id === undefined ||
      !this.$store.getters.orderItem.item_id
    ) {
      const self = this
      showNotice('請選擇方案、數量', function() {
        self.$router.push({ path: '/product' }).catch(() => {})
      })
    } else {
      getMember().then(response => {
        if (response.data.data.member_id !== undefined) {
          getItem(this.$store.getters.orderItem.item_id).then(response => {
            this.productDetail = response.data.data

            this.orderItem.init(
              this.$store.getters.orderItem,
              this.productDetail,
              '/product'
            )
            if (
              this.orderItem.type() === 'point' &&
              getStoreCode() === 'happygo'
            ) {
              happyGoTimeNotice()
            }
          })
        }
      })
    }
  },
  mounted() {},
  methods: {
    fillInfo() {
      this.$router.push({ path: '/order/user-info' }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.order-area {
  padding: 0 15px;
  .sub-title {
    text-align: center;
    font-size: 1.15rem;
    font-weight: bolder;
    color: rgba($main-color, $alpha: 1);
    background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba($font-color, $alpha: 0.5)),
      color-stop(0.5, transparent),
      to(transparent)
    );
    background-repeat: repeat-x;
    background-position: center center;
    -webkit-background-size: 2px;
    display: block;
    margin: 0 auto 0.5rem;
    span {
      padding: 0 35px;
      background-color: $white-color;
    }
  }

  .box-group {
    .box-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.25rem;
      .box-th {
        padding: 5px 15px 5px 0px;
        white-space: nowrap;
      }
      .box-td {
        text-align: right;
        padding-left: 10px;
        span {
          color: $main-color;
          font-weight: bolder;
        }
      }
    }
  }
  .box-article {
    font-size: 1rem;
    line-height: 1.85rem;
    color: rgba($font-color, $alpha: 1);
    padding-bottom: 2rem;
  }
}

.order-area-group > .order-area:first-child .box-group {
  margin: 0 auto 1.5rem;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
}
</style>
