import Vue from 'vue'
import { showNotice } from '@/utils/tool'

const orderItem = {
  item: null,
  productDetail: null,
  returnPath: '/',
  init(item, productDetail, returnPath) {
    this.item = item
    this.productDetail = productDetail
    this.returnPath = returnPath
  },
  searchObj(targetArray, targetKey, matchValue) {
    for (var i = 0; i < targetArray.length; i++) {
      if ((targetArray[i][targetKey] += '') === (matchValue += '')) {
        return targetArray[i]
      }
    }

    return null
  },
  plan() {
    const result = this.searchObj(
      this.productDetail.plans,
      'plan_id',
      this.item.plans[0].plan_id
    )

    if (!result) {
      const self = this
      showNotice('資料異常, 請重新處理', function() {
        self.$router
          .push({ path: this.returnPath + self.item.item_id })
          .catch(() => {})
      })
    } else {
      return result
    }
  },
  price() {
    const result = this.searchObj(
      this.plan().prices,
      'price_id',
      this.item.plans[0].prices[0].price_id
    )

    if (!result) {
      const self = this
      showNotice('資料異常, 請重新下單', function() {
        self.$router.push({ path: this.returnPath }).catch(() => {})
      })
    } else {
      return result
    }
  },
  point() {
    return this.searchObj(
      this.price().points,
      'point_id',
      this.item.plans[0].prices[0].point_id
    )
  },
  pricePointLabel() {
    const point = this.point()
    if (point) {
      return (
        point.point + '點 + NT$' + Vue.filter('priceFormat')(point.sale_price)
      )
    } else {
      return 'NT$' + Vue.filter('priceFormat')(this.price().sale_price)
    }
  },
  type() {
    return this.item.plans[0].prices[0].point_id ? 'point' : 'credit'
  },
  qty() {
    return this.item.plans[0].prices[0].qty
  },
  creditTotalPrice() {
    return this.price().sale_price * this.qty()
  },
  pointTotalPoint() {
    return this.point().point * this.qty()
  },
  pointTotalPrice() {
    return this.point().sale_price * this.qty()
  },
  priceName() {
    const result = this.searchObj(
      this.plan().prices,
      'price_id',
      this.item.plans[0].prices[0].price_id
    )
    if (!result) {
      const self = this
      showNotice('資料異常, 請重新下單', function() {
        self.$router.push({ path: this.returnPath }).catch(() => {})
      })
    } else {
      return result.price_name
    }
  }
}
export default orderItem
